<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <v-select
          :items="Seasons"
          item-text="Name"
          item-value="Id"
          :label="$t('Collection')"
          solo
          v-model="seasonSelecter"
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-select
          :items="CategorysSelector"
          item-text="Name"
          item-value="Id"
          :label="$t('Category')"
          solo
          v-model="categorysSelector"
          clearable
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="search"
          single-line
          append-icon="mdi-magnify"
          :label="$t('Search')"
          hide-details
          clearable
        >
          <v-icon slot="append" @click="searchItem()">
            mdi-magnify</v-icon
          ></v-text-field
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items.sync="products"
      :items-per-page.sync="itemsPerPage"
      :options.sync="options"
      class="elevation-1 ma-3 pa-4"
      hide-default-footer
      :loading="loading"
      :no-data-text="$t('No data available')"
    >
      <template v-slot:[`item.Seasons`]="{ item }">
        <v-layout>
          <p class="mb-0" v-for="(season, index) in item.Seasons" :key="index">
            <span v-if="index !== 0">,</span>
            {{ season.Code }}
          </p>
        </v-layout>
      </template>
      <template v-slot:[`item.CategoryId`]="{ item }">
        {{ getCategorieName(item.CategoryId) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          :href="location + '/productdetail/' + item.Id"
          elevation="2"
          fab
          x-small
        >
          <v-icon>mdi-eye-arrow-right</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer>
        <v-row class="mx-2 py-2" align="center" justify="center">
          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            <small
              >{{ $t("Page") }} {{ getPage }} {{ $t("out of") }}
              {{ getAmountOfPages }}</small
            >
          </span>
          <v-btn
            :disabled="hasPrevPage"
            fab
            x-small
            class="mr-1"
            @click="formerPage()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            :disabled="hasNextPage"
            fab
            x-small
            class="ml-1"
            @click="nextPage()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import request from "../../request";
import Vue from "vue";
import helpers from "../../helpers";

export default {
  data() {
    return {
      options: {},
      search: "",
      products: [],
      Categories: null,
      CategorysSelector: [],
      seasonSelecter: null,
      categorysSelector: null,
      Seasons: null,
      page: 1,
      amountOfPages: 1,
      count: 10,
      start: 0,
      itemsPerPage: 10,
      timer: null,
      seasonParam: "",
      categoryParam: "",
      loading: false,
      location: window.location.href,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Code"),
          align: "start",
          sortable: true,
          value: "Code",
        },
        {
          text: this.$t("Category"),
          sortable: true,
          value: "CategoryId",
        },
        {
          text: this.$t("Collection"),
          sortable: false,
          value: "Seasons",
        },
        {
          text: this.$t("Last modified"),
          sortable: true,
          value: "Modified",
        },
        {
          text: this.$t("Detail"),
          value: "action",
          sortable: false,
        },
      ];
    },
    getPage: {
      get() {
        return this.page;
      },
      set(newItem) {
        this.page = newItem;
      },
    },
    hasPrevPage() {
      if (this.getPage == 1) {
        return true;
      }
      return false;
    },
    hasNextPage() {
      if (this.getPage < this.amountOfPages) {
        return false;
      }
      return true;
    },
    getAmountOfPages: {
      get() {
        if (this.amountOfPages == 0) {
          return 1;
        }
        return this.amountOfPages;
      },
      set(newItem) {
        this.amountOfPages = newItem;
      },
    },
    currentLanguage: {
      get: function () {
        return Vue.i18n.locale();
      },
    },
  },
  watch: {
    search: function (params) {
      params ? params : (params = "");
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.search = params;

        this.resetPaging();
        this.getProducts({
          start: this.start,
          count: this.itemsPerPage,
          search: params,
          season: this.seasonParam,
          category: this.categoryParam,
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      }, 2000);
    },
    seasonSelecter: function (params) {
      this.seasonParam = params
        ? "&seasonIds=" + params
        : this.getActiveSeasons();

      this.resetPaging();
      this.getProducts({
        start: this.start,
        count: this.itemsPerPage,
        search: this.search ? this.search : "",
        season: this.seasonParam,
        category: this.categoryParam,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
    categorysSelector: function (params) {
      this.categoryParam = params ? "&categoryIds=" + params : "";
      this.resetPaging();
      this.getProducts({
        start: this.start,
        count: this.itemsPerPage,
        search: this.search ? this.search : "",
        season: this.seasonParam,
        category: this.categoryParam,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
    options: {
      handler() {
        this.getProducts({
          start: this.start,
          count: this.count,
          search: this.search ? this.search : "",
          season: this.seasonParam,
          category: this.categoryParam,
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      },
      deep: true,
    },
  },
  created() {
    request.get("/api/kdesign/productcategories", null, (res) => {
      if (res.success) {
        this.Categories = res;
        this.getCategorysSelector(res);
      }
    });
    request.get("/api/kdesign/seasons", null, (res) => {
      if (res.success) {
        const filterdActiveSeasons = res.filter((el) => el.Active == true);
        this.Seasons = filterdActiveSeasons;
        this.seasonParam = "";
        filterdActiveSeasons.forEach((element) => {
          this.seasonParam += "&seasonIds=" + element.Id;
        });
        this.getProducts({
          start: this.start,
          count: this.itemsPerPage,
          search: this.search ? this.search : "",
          season: this.seasonParam,
          category: this.categoryParam,
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      }
    });
  },
  methods: {
    resetPaging() {
      this.start = 0;
      this.count = 10;
      this.page = 1;
    },
    getActiveSeasons() {
      let searonsUrlIds = "";
      this.Seasons.forEach((el) => {
        searonsUrlIds += "&seasonIds=" + el.Id;
      });
      return searonsUrlIds;
    },
    getCategorysSelector(res) {
      res.forEach((element) => {
        let findNameFromElement = element.Code;
        if (element.Names !== null) {
          findNameFromElement = element.Names.find(
            (el) => el.LanguageCode == this.currentLanguage
          ).Value;
        }

        this.CategorysSelector.push({
          Id: element.Id,
          Name: element.Names
            ? this.capitalizeFirstLetter(findNameFromElement)
            : this.capitalizeFirstLetter(element.Code.toLowerCase()),
        });
      });
      this.CategorysSelector.sort((a, b) => a.Name.localeCompare(b.Name));
    },
    getCategorieName(item) {
      if (item && this.Categories.length) {
        const category = this.Categories.find((el) => {
          return el.Id == item;
        });
        if (category.Names) {
          const findCategoryName = category.Names.find((el) => {
            return el.LanguageCode == this.currentLanguage;
          }).Value;
          return this.capitalizeFirstLetter(findCategoryName);
        }
        return this.capitalizeFirstLetter(category.Code.toLowerCase());
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    goTo(item) {
      this.$router.push({
        name: "productDetail",
        params: {
          id: this.$route.params.id,
          productId: item.Id,
        },
      });
      this.$router.go(1);
    },
    getProducts(items) {
      this.loading = true;
      request.get(
        "/api/kdesign/products?start=" +
          items.start +
          "&count=" +
          items.count +
          "&sort=" +
          items.sort +
          "&sortDescending=" +
          items.sortDescending +
          "&search=" +
          items.search +
          items.season +
          items.category,
        null,
        (res) => {
          if (res.success) {
            let fullProducts = [];
            res.forEach((element) => {
              fullProducts.push({
                Id: element.Id,
                Code: element.Code,
                Seasons: element.Seasons,
                Modified: helpers.changeTimeToReadeble(element.Modified),
                CategoryId: element.CategoryId,
              });
            });

            this.products = fullProducts;
            this.loading = false;
          } else {
            this.loading = false;
          }
        }
      );
      this.productCounter(items);
    },
    productCounter(items) {
      request.get(
        "/api/kdesign/productscount?" +
          "search=" +
          items.search +
          items.season +
          items.category,
        null,
        (res) => {
          this.amountOfPages = Math.ceil(res.item / this.itemsPerPage);
        }
      );
    },
    nextPage() {
      this.page++;
      this.start = this.start + this.itemsPerPage;
      this.getProducts({
        start: this.start,
        count: this.itemsPerPage,
        search: this.search ? this.search : "",
        season: this.seasonParam,
        category: this.categoryParam,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },

    formerPage() {
      this.page--;
      this.start = this.start - this.itemsPerPage;
      this.getProducts({
        start: this.start,
        count: this.itemsPerPage,
        search: this.search ? this.search : "",
        season: this.seasonParam,
        category: this.categoryParam,
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
  },
};
</script>

<style>
</style>